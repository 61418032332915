import React from "react";

const Heading = (props) => {
  return (
    <h1
      style={props.style}
      className={`text-[40px] sm:text-[25px] leading-[60px] sm:leading-[42px] font-[600] ${props.className}`}
    >
      {props.children}
    </h1>
  );
};

export default Heading;
